<template>
  <section class="lista">
    <div class="itens">
      <!-- Template -->
      <div class="template" v-for="(template, index) in state.templates" :key="index">
        <div class="autor">
          <div class="foto" :style="`background-image: url('${template.iconeAutor}');`"></div>
          <p>{{ template.autor }}</p>
        </div>
        <div class="descricao">
          <p>{{ template.descricao }}</p>
        </div>
        <div class="capa">
          <div class="tamanho">
            <div class="bloqueado" v-if="!template.free && storePerfil.usuario?.assinatura?.tipo === 'free'">
              <Svgs nome="cadeado" />
              <p>Assine para ter acesso</p>
            </div>
            <div class="imagem" :style="`background-image: url('${template.imagemCapa}');`"></div>
          </div>
        </div>
        <div class="botoes">
          <div class="tag">
            <h3>{{ template.tipo }}</h3>
          </div>
          <a class="botao previa" target="_blank" :href="`https://unic.page/${template.hotlink}`">
            <Svgs nome="visualizar" />
          </a>
          <button class="botao usar" @click="abrirModalCriarComTemplate(index)" v-if="template.free || storePerfil.usuario?.assinatura?.tipo !== 'free'">
            <Svgs nome="check" />
            <p>Escolher</p>
          </button>
        </div>
        <h5 v-if="template.novo" class="novo">NOVO</h5>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive } from 'vue'
import { useStoreTemplates, useStoreModal, useStorePerfil } from '@stores'
import Svgs from '@svgs'

const storeTemplates = useStoreTemplates()
const storeModal = useStoreModal()
const storePerfil = useStorePerfil()

const state = reactive({
  templates: [
    {
      hotlink: 'template-estetica-automotiva',
      autor: 'UnicPages',
      tipo: 'Página de Vendas',
      descricao: 'Modelo para Estéticas Automotivas e Lava-jatos',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-unic-estetica-automotiva.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-unic.png',
      novo: false,
      free: true
    },
    {
      hotlink: 'template-imobiliaria',
      autor: 'Edu Sites',
      tipo: 'Página de Vendas',
      descricao: 'Modelo para Imobiliárias e Corretores',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-unic-imobiliaria.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-edu.png',
      novo: false,
      free: true
    },
    {
      hotlink: 'template-clinica-medica',
      autor: 'UnicPages',
      tipo: 'Página de Vendas',
      descricao: 'Modelo para Clínicas e Médicos',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-unic-clinica-medica.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-unic.png',
      novo: false,
      free: true
    },
    {
      hotlink: 'template-bio-insta',
      autor: 'Eu Sou o Mac',
      tipo: 'Link de Bio',
      descricao: 'Modelo para link de bio',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-power-bio-instagram.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-mac.png',
      novo: false,
      free: false
    },
    {
      hotlink: 'template-power-samia',
      autor: 'Produtora Power',
      tipo: 'Página de Vendas',
      descricao: 'Modelo para produto feminino',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-power-samia.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-power.png',
      novo: false,
      free: false
    },
    {
      hotlink: 'template-exp-trafego-pagox',
      autor: 'Edu Sites',
      tipo: 'Página de Vendas',
      descricao: 'Modelo para gestor de tráfego',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-template-trafego-pagox.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-edu.png',
      novo: false,
      free: false
    },
    {
      hotlink: 'template-power-diogo',
      autor: 'Diogo Viana',
      tipo: 'Página de Vendas',
      descricao: 'Modelo para curso ou mentoria',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-power-diogo.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-diogo.png',
      novo: false,
      free: false
    },
    {
      hotlink: 'template-power-programa-ex',
      autor: 'Saulo Barbosa',
      tipo: 'Página de Captura',
      descricao: 'Modelo para página de captura',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-power-programa-ex.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-saulo.png',
      novo: false,
      free: false
    },
    {
      hotlink: 'template-power-coprodutor7d',
      autor: 'Eu Sou o Mac',
      tipo: 'Página de Captura',
      descricao: 'Modelo para captura de evento',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-power-coprodutor7d.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-mac.png',
      novo: false,
      free: false
    },
    {
      hotlink: 'template-power-programa-elite',
      autor: 'Eu Sou o Mac',
      tipo: 'Página de Vendas',
      descricao: 'Modelo para curso ou treinamento online',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-programa-elite.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-mac.png',
      novo: false,
      free: false
    },
    {
      hotlink: 'template-power-ingresso-rj',
      autor: 'Produtora Power',
      tipo: 'Página de Vendas',
      descricao: 'Modelo para eventos presenciais',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-template-ingresso-rj.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-power.png',
      novo: false,
      free: false
    },
    {
      hotlink: 'template-exp-marketing',
      autor: 'Edu Sites',
      tipo: 'Página de Vendas',
      descricao: 'Modelo para portfólio ou vitrine',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-template-xp-marketing.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-edu.png',
      novo: false,
      free: false
    },
    {
      hotlink: 'template-power-macletter',
      autor: 'Eu Sou o Mac',
      tipo: 'Página de Captura',
      descricao: 'Modelo para captura simples',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-power-macletter.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-mac.png',
      novo: false,
      free: false
    },
    {
      hotlink: 'template-exp-turnkey',
      autor: 'Edu Sites',
      tipo: 'Página de Captura',
      descricao: 'Modelo para página de captura',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-exp-turnkey.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-edu.png',
      novo: false,
      free: false
    }
  ]
})

function formatarTipo(texto) {
  texto = texto
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
  texto = texto.replace(/\s+/g, '')

  return texto
}

function abrirModalCriarComTemplate(index) {
  storeTemplates.hotlinkTemplate = state.templates[index].hotlink
  storeTemplates.imagemCapa = state.templates[index].imagemCapa
  storeTemplates.iconeAutor = state.templates[index].iconeAutor
  storeTemplates.autor = state.templates[index].autor
  storeTemplates.tipo = state.templates[index].tipo
  storeModal.abrirModal('criarComTemplate', 'template', 'Criar com template')
}
</script>

<style scoped>
section.lista {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0 0 20px;
}

.itens {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-height: 75dvh;
}

.template {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(25% - 15px);
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  position: relative;
  margin: 0 15px 15px 0;
  transition: all 0.3s;
  animation: fadeIn 0.3s linear;
}

h5.novo {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 7px 20px;
  border-radius: 30px;
  background-color: var(--cor-azul);
  box-shadow: 0 10px 30px 0 #1c94ef95;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.template .capa {
  width: 100%;
}

.template .capa .tamanho {
  position: relative;
  padding-bottom: 56.52%;
  width: 100%;
}

.template .capa .tamanho .bloqueado {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #6d051a9c;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  z-index: 2;
}

.template .capa .tamanho .bloqueado svg {
  width: 30px;
  min-width: 30px;
  fill: var(--cor-branco);
  margin: 0 0 15px 0;
}

.template .capa .tamanho .bloqueado p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.template .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--cor-cinza-2);
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.template .descricao {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 20px 20px 20px;
}

.template .descricao p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
}

.template .autor {
  display: flex;
  align-items: center;
  margin-right: auto;
  width: 100%;
  background-color: var(--cor-cinza-2);
  padding: 20px 20px 10px 20px;
  border-radius: 10px 10px 0 0;
}

.template .autor .foto {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: var(--cor-cinza-4);
  background-size: cover;
  background-position: center;
  margin: 0 5px 0 0;
}

.template .autor p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.template .botoes {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px 20px;
}

.template .botoes .tag {
  padding: 5px 10px;
  border-radius: 50px;
  background-color: var(--cor-cinza-3);
  border: 1px solid var(--cor-cinza-4);
  margin-right: auto;
}

.template .botoes .tag h3 {
  font-family: var(--bold);
  font-size: var(--f0);
  color: var(--cor-branco);
}

.template .botoes a {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
  width: 35px;
  height: 35px;
  margin: 0 10px 0 0;
  transition: all 0.3s;
}

.template .botoes a svg {
  width: 17px;
  min-width: 17px;
  max-height: 17px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

.template .botoes a:hover {
  background-color: var(--cor-laranja);
}

.template .botoes a:hover svg {
  fill: var(--cor-branco-fixo);
}

.template .botoes button.usar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-azul);
  padding: 8px 12px;
  border-radius: 10px;
  transition: all 0.3s;
}

.template .botoes button.usar:hover {
  background-color: var(--cor-azul-escuro);
}

.template .botoes button.usar p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
}

.template .botoes button.usar p {
  font-family: var(--bold);
}

.template .botoes button.usar svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-branco-fixo);
  margin: 0 8px 0 0;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  section.lista {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 20px 20px 0 20px;
    min-height: 75dvh;
  }

  .opcoes {
    flex-wrap: wrap;
    width: 100%;
    padding: 10px;
  }

  .opcoes button {
    padding: 15px;
    width: 50%;
  }

  .opcoes button p {
    font-size: var(--f1);
  }

  .opcoes button h5 {
    font-size: var(--f0);
  }

  .itens {
    padding: 0;
  }

  .template {
    width: 100%;
    margin: 0 0 20px 0;
  }

  .template .capa {
    width: 100%;
  }

  .template .autor .foto {
    width: 16px;
    height: 16px;
  }
}
</style>
