<template>
  <div class="text">
    <h3>Texto</h3>
    <textarea rows="3" spellCheck="false" placeholder="Digite aqui" @input="editarText()" v-model="state.text" v-redimensionar-textarea></textarea>

    <div class="unicai">
      <button class="abrir" @click="state.unicAi = !state.unicAi" :class="{ ativo: state.unicAi }">
        <Svgs nome="unic-ai" />
        <p>Gerar com I.A</p>
      </button>
      <div class="chat" :class="{ mostrar: state.unicAi }">
        <div class="titulo">
          <p>UnicPages I.A</p>
          <button class="fechar" @click="state.unicAi = !state.unicAi">
            <Svgs nome="x" />
          </button>
        </div>
        <div class="conteudo">
          <div class="mensagens" v-for="message in messages" :key="message.id">
            <div class="unic" v-if="message.author === 'ai'">
              <Svgs class="icone" nome="unic-ai" />
              <p v-html="helperTexto.converterTextoEmFormatoHtml(message.text)"></p>
              <button @click="usarTexto(message.text)">
                <Svgs nome="check" />
                Usar esse texto
              </button>
            </div>
            <div class="voce" v-if="message.author === 'user'">
              <p v-html="helperTexto.converterTextoEmFormatoHtml(message.text)"></p>
              <div class="foto" :style="`background-image: url(${storePerfil.usuario?.imagemPerfil || 'https://arquivos.unicpages.com/imagens/app/icones/placeholder-perfil.svg'})`"></div>
            </div>
          </div>
          <div class="campo">
            <textarea v-model="userInput" spellcheck="false" placeholder="Crie uma frase sobre..."></textarea>
            <button @click="sendMessage">
              <Svgs nome="seta" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, watchEffect, ref } from 'vue'
import { useStoreAjustes, useStorePerfil, useStoreGpt } from '@stores'
import { helperTexto } from '../../../../helpers/index.js'
import Svgs from '@svgs'

const storePerfil = useStorePerfil()
const storeGpt = useStoreGpt()
const storeAjustes = useStoreAjustes()

function carregarPerfil() {
  storePerfil.receberPerfilUsuario()
}

const props = defineProps(['selecao'])
const state = reactive({
  text: '',
  unicAi: false
})

function editarText() {
  elementoSelecionado().text = state.text
}

function carregarText() {
  state.text = elementoSelecionado().text
}

function elementoSelecionado() {
  const dispositivo = props?.selecao?.dispositivo
  const indice = props?.selecao?.indiceSecao
  const elemento = props?.selecao?.elementoSelecionado
  return storeAjustes.secoes[dispositivo][indice]?.propriedades[elemento] || {}
}

function usarTexto(texto) {
  elementoSelecionado().text = texto
  state.unicAi = false
}

const messages = ref([])
const userInput = ref('')

const sendMessage = async () => {
  const userMessage = userInput.value.trim()
  if (!userMessage) return

  messages.value.push({ id: Date.now(), text: userMessage, author: 'user' })

  try {
    const payload = { mensagem: userMessage }
    const resp = await storeGpt.chat(payload)
    messages.value.push({ id: Date.now(), text: resp, author: 'ai' })
  } catch (error) {
    console.error('Erro ao enviar mensagem:', error)
  }

  userInput.value = ''
}

watchEffect(() => {
  carregarText()
  carregarPerfil()
})
</script>

<style scoped>
.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
  animation: fadeIn 0.3s ease-in-out;
  position: relative;
}

h3 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 10px 0;
}

textarea {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  background-color: var(--cor-cinza-3);
  padding: 15px;
  border-radius: 10px;
  resize: none;
  line-height: 1.5;
  width: 100%;
  transition: all 0.3s;
}

textarea:focus {
  background-color: var(--cor-cinza-4);
}

textarea::placeholder {
  color: var(--cor-cinza-5);
}

.unicai {
  width: 100%;
}

button.abrir {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: transparent;
  transition: all 0.3s;
  position: absolute;
  top: 20px;
  right: 20px;
}

button.abrir.ativo p {
  color: var(--cor-branco);
}

button.abrir:hover p {
  color: var(--cor-branco);
}

button.abrir svg {
  width: 13px;
  max-width: 13px;
  max-height: 13px;
  fill: var(--cor-branco);
  margin: 0 5px 0 0;
}

button.abrir p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  transition: all 0.3s;
}

.titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--cor-cinza-3);
  border-radius: 10px 10px 0 0;
  z-index: 2;
}

.titulo p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
}

.titulo button.fechar {
  background-color: transparent;
}

.titulo button.fechar svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-vermelho);
  transition: all 0.3s;
}

.titulo button.fechar:hover svg {
  fill: var(--cor-branco);
}

.chat.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.chat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  bottom: 20px;
  right: 290px;
  width: 100%;
  max-width: 400px;
  height: 70dvh;
  max-height: 70dvh;
  min-height: 70dvh;
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 4;
  transition: all 0.3s;
}

.conteudo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 60dvh;
  max-height: 60dvh;
  padding: 60px 10px 20px 10px;
  overflow-y: scroll;
}

.conteudo::-webkit-scrollbar {
  background-color: var(--cor-cinza-2);
}

.conteudo::-webkit-scrollbar-thumb {
  background-color: var(--cor-cinza-3);
}

.conteudo::-webkit-scrollbar-track {
  background-color: var(--cor-cinza-2);
}

.chat .campo {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 5;
}

.chat .campo textarea {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  background-color: var(--cor-cinza-3);
  width: 100%;
  padding: 20px 70px 20px 20px;
  border-radius: 0 0 10px 10px;
  transition: all 0.3s;
}

.chat .campo textarea:focus {
  background-color: var(--cor-cinza-4);
}

.chat .campo button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  right: 10px;
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(40deg, var(--degrade));
  transition: all 0.3s;
}

.chat .campo button svg {
  width: 20px;
  height: 20px;
  fill: var(--cor-branco);
  transform: rotate(-90deg);
  transition: all 0.3s;
}

.chat .campo button:hover svg {
  transform: rotate(-90deg) scale(0.8);
}

.chat .campo button:hover {
  filter: brightness(115%);
}

.chat .visualizacao {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.chat .mensagens {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.chat .voce {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  margin: 0 0 20px 0;
  position: relative;
}

.chat .unic {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin: 0 0 20px 0;
  position: relative;
}

.chat .voce .foto {
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--cor-preto);
  background-size: cover;
  background-position: center;
  margin: 0 0 0 10px;
}

.chat .voce p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  line-height: 1.5;
  text-align: right;
  padding: 0 0 0 20px;
  border-radius: 10px 0 10px 10px;
  color: var(--cor-cinza-5);
}

.chat .unic svg.icone {
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
}

.chat .unic p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  line-height: 1.5;
  padding: 0 20px 45px 0;
  border-radius: 0 10px 10px 10px;
  text-align: left;
}

.chat .unic button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  background-color: var(--cor-cinza-3);
  height: 35px;
  border-radius: 50px;
  padding: 0 15px;
  position: absolute;
  bottom: 0;
  left: 30px;
  transition: all 0.3s;
}

.chat .unic button:hover {
  color: var(--cor-branco);
  background-color: var(--cor-cinza-4);
}

.chat .unic button:hover svg {
  fill: var(--cor-branco);
}

.chat .unic button svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-cinza-5);
  margin: 0 5px 0 0;
  transition: all 0.3s;
}

@media screen and (max-width: 1024px) {
  .chat {
    right: 20px;
    width: calc(100% - 40px);
    max-width: calc(100% - 40px);
    height: 55dvh;
    max-height: 55dvh;
    min-height: 55dvh;
  }

  .conteudo {
    height: 55dvh;
    max-height: 55dvh;
  }
}
</style>
