import { defineStore } from 'pinia'
import { useStoreAlerta } from '@stores'
import apiUsuarios from '@/api/unicpages/api-usuarios'

export const useStorePerfil = defineStore('storePerfil', {
  state: () => {
    return {
      usuario: null,
      usuarioPorEmail: {},
      mostrarModalEditarPerfil: false,
      mostrarModalAlterarSenha: false
    }
  },
  actions: {
    async receberPorEmail(email) {
      try {
        const resp = await apiUsuarios.receberPorEmail(email)
        this.usuarioPorEmail = resp
        return true
      } catch (error) {
        this.usuarioPorEmail = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },
    async receberPerfilUsuario() {
      try {
        const resp = await apiUsuarios.receberPerfilUsuario()
        this.usuario = resp
        return true
      } catch (error) {
        this.usuario = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async alterarDados(payload) {
      try {
        const resp = await apiUsuarios.alterarDados(payload)
        return useStoreAlerta().exibirSucessoRequest(resp)
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async alterarSenha(payload) {
      try {
        const resp = await apiUsuarios.alterarSenha(payload)
        return useStoreAlerta().exibirSucessoRequest(resp)
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  }
})
