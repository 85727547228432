<template>
  <div class="cor">
    <h3>Cor</h3>
    <ColorPicker :valor="elementoSelecionado.color" :onAtualizarCor="atualizarCorSelecionada" />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStoreAjustes } from '@stores'
import ColorPicker from '@/components/global/elementos/ColorPicker.vue'

const props = defineProps(['selecao'])
const storeAjustes = useStoreAjustes()

const elementoSelecionado = computed(() => {
  const dispositivo = props.selecao?.dispositivo
  const indice = props.selecao?.indiceSecao
  const elemento = props.selecao?.elementoSelecionado
  return storeAjustes.secoes[dispositivo][indice]?.propriedades[elemento] || {}
})

function atualizarCorSelecionada(novaCor) {
  elementoSelecionado.value.color = novaCor
}
</script>

<style scoped>
.cor {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: relative;
  padding: 20px 20px 0 20px;
}

h3 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  padding: 0 0 10px 0;
}
</style>
