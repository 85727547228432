import { api } from '../../api/unicpages/axios'
import { helperToken } from '../../helpers/index.js'

const idUsuario = helperToken.idUsuario()
const token = helperToken.receberToken()

export default {
  receberTodos() {
    if (!idUsuario) return

    const method = 'GET'
    const url = `pages/${idUsuario}`

    return api(method, url, null, token)
  },

  receberCompartilhados() {
    if (!idUsuario) return

    const method = 'GET'
    const url = `pages/compartilhados/${idUsuario}`

    return api(method, url, null, token)
  },

  receberPorHotlink(hotlink) {
    if (!idUsuario || !hotlink) return

    const method = 'GET'
    const url = `pages/${idUsuario}/busca?hotlink=${hotlink}`

    return api(method, url, null, token)
  },

  receberPorId(idPage) {
    if (!idUsuario) return

    const method = 'GET'
    const url = `pages/${idUsuario}/busca?id=${idPage}`

    return api(method, url, null, token)
  },

  adicionar(payload) {
    if (!idUsuario) return

    const method = 'POST'
    const url = `pages/${idUsuario}`

    return api(method, url, payload, token)
  },

  alterarDados(payload) {
    if (!idUsuario) return

    const method = 'PUT'
    const url = `pages/${idUsuario}/${payload.idPage}`

    return api(method, url, payload, token)
  },

  deletar(idPage) {
    if (!idUsuario) return

    const method = 'DELETE'
    const url = `pages/${idUsuario}/${idPage}`

    return api(method, url, {}, token)
  },

  ordenarPosicoes(payload) {
    const method = 'PATCH'
    const url = `pages/posicao/ordenar/${idUsuario}`
    const dados = payload

    return api(method, url, dados, token)
  },

  alterarDominio(payload) {
    if (!idUsuario) return

    const method = 'PATCH'
    const url = `pages-extras/dominio-personalizado/alterar/${idUsuario}/${payload.idPage}`

    return api(method, url, payload, token)
  },

  removerDominio(payload) {
    if (!idUsuario) return

    const method = 'PATCH'
    const url = `pages-extras/dominio-personalizado/remover/${idUsuario}/${payload.idPage}`

    return api(method, url, payload, token)
  },

  publicar(idPage) {
    if (!idUsuario) return

    const method = 'PATCH'
    const url = `pages-extras/publicar/${idUsuario}/${idPage}`

    return api(method, url, {}, token)
  },

  duplicar(payload) {
    if (!idUsuario) return

    const method = 'POST'
    const url = `pages-extras/duplicar/${idUsuario}/${payload.idPage}`

    return api(method, url, payload, token)
  },

  clonarTemplate(payload) {
    if (!idUsuario) return

    const method = 'POST'
    const url = `pages-extras/clonar/template/${idUsuario}/${payload.hotlinkTemplate}`

    return api(method, url, payload, token)
  },

  alterarRascunho(payload) {
    if (!idUsuario) return

    const method = 'PATCH'
    const url = `pages-extras/rascunho/alterar/${idUsuario}/${payload.idPage}`

    return api(method, url, payload, token)
  }
}
