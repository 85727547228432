<template>
  <section class="lista">
    <div class="busca">
      <div class="input">
        <Svgs class="icone" nome="lupa" />
        <input type="text" placeholder="Buscar site compartilhado..." spellcheck="false" v-model="searchQuery" />
      </div>
      <div class="filtrar">
        <Svgs nome="setinha" />
        <select v-model="selectedFilter">
          <option value="todos">Todos</option>
          <option value="alfabetica-asc">Alfabética A-Z</option>
          <option value="alfabetica-desc">Alfabética Z-A</option>
          <option value="mais-antigos">Mais antigos</option>
          <option value="mais-novos">Mais novos</option>
          <option value="online">Online</option>
          <option value="offline">Offline</option>
        </select>
      </div>
    </div>
    <div class="itens">
      <div class="item" v-for="page in paginasFiltradas" :key="page._id">
        <div class="capa">
          <div class="tamanho">
            <div class="imagem" :style="`background-image: url('${page.imagemCapa || 'https://arquivos.unicpages.com/imagens/app/capa-site-unic.png'}')`"></div>
          </div>
        </div>
        <div class="info">
          <div class="nome">
            <h3>{{ page?.nome }}</h3>
            <p>{{ page?.descricao.length > 60 ? page.descricao.substring(0, 60) + '...' : page.descricao }}</p>
          </div>
          <div class="status" :class="page?.online ? 'on' : 'off'">
            <span></span>
            <h3>{{ page?.online ? 'Online' : 'Offline' }}</h3>
          </div>
        </div>
        <div class="links">
          <a class="dominio" target="_blank" :href="`https://${page.dominioPersonalizado}`" :class="{ sem: !page.dominioPersonalizado }">
            {{ page.dominioPersonalizado || 'Sem domínio' }}
          </a>
          <a class="hotlink" target="_blank" :href="`https://unic.page/${page.hotlink}`">unic.page/{{ page.hotlink }}</a>
        </div>
        <div class="botoes">
          <div class="dono">
            <div class="perfil" :style="`background-image: url('${page.usuario?.imagemPerfil || 'https://arquivos.unicpages.com/imagens/app/icones/placeholder-perfil.svg'}')`"></div>
            <p>Site de {{ page.usuario?.nome }}</p>
          </div>
          <button class="editar" @click="editarSite(page?.hotlink)">
            <Svgs nome="editar" />
            <p>Editar site</p>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStorePages } from '@stores'
import Svgs from '@svgs'

const storePages = useStorePages()

const searchQuery = ref('')
const selectedFilter = ref('mais-novos')

const paginasFiltradas = computed(() => {
  let pages = storePages.pagesCompartilhados || []

  if (searchQuery.value) {
    const query = searchQuery.value.toLowerCase()
    pages = pages.filter((page) => page.nome.toLowerCase().includes(query) || page.descricao.toLowerCase().includes(query))
  }

  switch (selectedFilter.value) {
    case 'alfabetica-asc':
      pages = pages.slice().sort((a, b) => a.nome.localeCompare(b.nome))
      break
    case 'alfabetica-desc':
      pages = pages.slice().sort((a, b) => b.nome.localeCompare(a.nome))
      break
    case 'mais-antigos':
      pages = pages.slice().sort((a, b) => new Date(a.dataRegistro) - new Date(b.dataRegistro))
      break
    case 'mais-novos':
      pages = pages.slice().sort((a, b) => new Date(b.dataRegistro) - new Date(a.dataRegistro))
      break
    case 'online':
      pages = pages.filter((page) => page.online)
      break
    case 'offline':
      pages = pages.filter((page) => !page.online)
      break
  }

  return pages
})

function editarSite(hotlink) {
  window.location.href = `/criar/${hotlink}`
}
</script>

<style scoped>
section.lista {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 5px 0 20px;
  min-height: 85dvh;
}

.busca {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0 15px 20px 0;
}

.busca .input {
  width: calc(70% - 10px);
}

.busca button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 10px;
  width: calc(30% - 10px);
  background-color: var(--cor-cinza-2);
  transition: all 0.3s;
}

.busca button:hover {
  background-color: var(--cor-cinza-3);
}

.busca button svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

.busca button p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.busca .filtrar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: calc(30% - 10px);
}

.busca .filtrar svg {
  position: absolute;
  right: 15px;
  width: 10px;
  min-width: 10px;
  fill: var(--cor-cinza-5);
}

.itens {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(33.33% - 15px);
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  margin: 0 15px 20px 0;
}

.item .capa {
  width: 100%;
}

.item .capa .tamanho {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 20%;
  overflow: hidden;
}

.item .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--cor-cinza-3);
  border-radius: 10px 10px 0 0;
  background-size: cover;
  background-position: center;
}

.item .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}

.item .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 20px 0 0;
}

.item .nome h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.item .nome p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.item .status {
  display: flex;
  align-items: center;
  background-color: var(--cor-cinza-3);
  padding: 10px 15px;
  border-radius: 50px;
}

.item .status span {
  width: 5px;
  min-width: 5px;
  height: 5px;
  min-height: 5px;
  border-radius: 50%;
  margin: 0 5px 0 0;
}

.item .status h3 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.item .status.on span {
  background-color: var(--cor-azul);
}

.item .status.off span {
  background-color: var(--cor-vermelho);
}

.item .links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid var(--cor-cinza-3);
  border-bottom: 1px solid var(--cor-cinza-3);
}

.item .links a.dominio {
  border-right: 2px solid var(--cor-cinza-3);
}

.item .links a {
  text-align: center;
  width: 50%;
  background-color: transparent;
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  padding: 15px 5px;
  transition: all 0.3s;
}

.item .links a.sem {
  opacity: 0.5;
  pointer-events: none;
}

.item .links a:hover {
  color: var(--cor-azul);
}

.item .botoes .dono {
  display: flex;
  align-items: center;
}

.item .botoes .dono .perfil {
  width: 20px;
  height: 20px;
  background-size: cover;
  background-position: center;
}

.item .botoes .dono p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 0 5px;
}

.item .botoes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}

.item .botoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-azul);
  padding: 8px 12px;
  border-radius: 10px;
  transition: all 0.3s;
}

.item .botoes button:hover {
  background-color: var(--cor-azul-escuro);
}

.item .botoes button p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
}

.item .botoes button svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-branco-fixo);
  margin: 0 8px 0 0;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  section.lista {
    padding: 20px 20px 0 20px;
  }

  .busca {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 0 20px 0;
  }

  .busca .input {
    width: 60%;
    margin: 0;
  }

  .busca button {
    width: calc(50% - 10px);
  }

  .busca .filtrar {
    width: calc(40% - 15px);
  }

  .item {
    width: 100%;
    margin: 0 0 20px 0;
  }

  .item .info {
    width: 100%;
    padding: 20px;
  }

  .item .foto {
    width: 40px;
    height: 40px;
  }

  .item .nome {
    padding: 0 20px 0 0;
  }

  .item .nome h3 {
    font-size: var(--f1);
  }

  .item .nome p {
    font-size: var(--f0);
  }

  .item .status {
    padding: 8px 12px;
  }

  .item .status h3 {
    font-size: var(--f0);
  }

  .item .links {
    flex-direction: column;
  }

  .item .links a.dominio {
    border-bottom: 2px solid var(--cor-cinza-3);
    border-right: none;
  }

  .item .links a {
    width: 100%;
    font-size: var(--f0);
    padding: 13px 5px;
  }

  .item .botoes {
    padding: 20px;
  }

  .item .botoes button p {
    font-size: var(--f1);
  }

  .item .botoes button svg {
    width: 10px;
    min-width: 10px;
    margin: 0 8px 0 0;
  }
}
</style>
