<template>
  <section class="modalCss" :class="{ mostrar: mostrarModalCssPersonalizado }">
    <div class="titulo">
      <Svgs nome="codigo" />
      <div class="texto">
        <h3>CSS Personalizado</h3>
      </div>
      <button @click="fecharModal">
        <Svgs nome="x" />
      </button>
    </div>

    <MonacoEditor class="editor" v-model="state.css" language="css" />

    <div class="botao">
      <BotaoSalvar nome="Salvar CSS personalizado" @click="salvarCssPersonalizado" />
    </div>
  </section>
</template>

<script setup>
import { reactive, onMounted, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useStoreCriar, useStorePages } from '@stores'
import Svgs from '@svgs'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'
import MonacoEditor from '@components/global/editor/Editor.vue'

const storeCriar = useStoreCriar()
const storePages = useStorePages()
const { mostrarModalCssPersonalizado } = storeToRefs(storeCriar)

const state = reactive({
  css: ''
})

function fecharModal() {
  storeCriar.mostrarModalCssPersonalizado = false
}

document.addEventListener('keydown', (event) => {
  if (event.key === 'Escape') fecharModal()
})

function salvarCssPersonalizado() {
  const payload = {
    idPage: storePages.page?._id,
    cssPersonalizado: state.css
  }

  storePages.alterarDados(payload).then(() => {
    storePages.page.cssPersonalizado = state.css
    criarTagStyle()
  })
}

function carregarCss() {
  state.css = storePages.page?.cssPersonalizado
}

const criarTagStyle = () => {
  const cssPersonalizado = storePages.page?.cssPersonalizado || state.css
  if (cssPersonalizado) {
    let styleTag = document.getElementById('cssPersonalizado')
    if (!styleTag) {
      styleTag = document.createElement('style')
      styleTag.id = 'cssPersonalizado'
      document.head.appendChild(styleTag)
    }
    styleTag.innerHTML = cssPersonalizado
  }
}

watch(
  () => storePages.page,
  (newPage) => {
    if (newPage) {
      carregarCss()
      criarTagStyle()
    }
  },
  { immediate: true }
)

onMounted(() => {
  carregarCss()
  criarTagStyle()
})
</script>

<style scoped>
section.modalCss {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 500px;
  height: 100dvh;
  max-height: 100dvh;
  min-height: 100dvh;
  background-color: var(--cor-cinza-2);
  overflow-y: scroll;
  z-index: 11;
  opacity: 0;
  visibility: visible;
  pointer-events: none;
  transition: all 0.3s;
}

section.modalCss.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  z-index: 12;
}

section.modalCss.fechar {
  opacity: 0;
  visibility: visible;
  pointer-events: none;
}

.editor {
  width: 100%;
  height: calc(100% - 190px);
  min-height: calc(100% - 190px);
  max-height: calc(100% - 190px);
}

.titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-bottom: 2px solid var(--cor-cinza-3);
}

.titulo svg {
  width: 25px;
  min-width: 25px;
  margin: 0 10px 0 0;
  fill: var(--cor-branco);
}

.titulo .texto h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.titulo button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  width: 40px;
  height: 40px;
  background: transparent;
  margin-left: auto;
  transition: all 0.3s;
}

.titulo button svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

.titulo button:hover svg {
  fill: var(--cor-vermelho);
}

.pixel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
}

.botao {
  width: 100%;
  padding: 20px;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  section.modalCss {
    max-width: 100%;
    height: 50dvh;
    max-height: 50dvh;
    min-height: 50dvh;
    border-radius: 0 0 10px 10px;
  }

  .titulo {
    padding: 20px;
  }

  .botao {
    padding: 20px;
  }

  .campos {
    padding: 20px 20px 0 20px;
  }

  .pixel {
    padding: 20px 20px 0 20px;
  }

  .pixel .linha {
    padding: 0 0 0 10px;
  }
}
</style>
