<template>
  <Modal nome="posicoes">
    <div class="conteudo">
      <div class="itens">
        <div class="item" v-for="(site, index) in state.listaSites" :key="index">
          <div class="posicao">
            <p>{{ index + 1 }}</p>
          </div>
          <div class="capa">
            <div class="tamanho">
              <div class="imagem" :style="`background-image: url('${site.imagemCapa || 'https://arquivos.unicpages.com/imagens/app/capa-site-unic.png'}')`"></div>
            </div>
          </div>
          <div class="modulo">
            <h3>{{ formatarTitulo(site.nome) }}</h3>
            <p>{{ formatarTitulo(site.descricao) }}</p>
          </div>
          <button class="mover cima" @click="moverParaCima(index)" :disabled="index === 0">
            <Svgs nome="seta" />
          </button>
          <button class="mover baixo" @click="moverParaBaixo(index)" :disabled="index === state.listaSites?.length - 1">
            <Svgs nome="seta" />
          </button>
        </div>
      </div>

      <BotaoSalvar nome="Salvar Posição" @click="salvarPosicoes" />
    </div>
  </Modal>
</template>

<script setup>
import { inject, onMounted, reactive } from 'vue'
import { useStoreModal, useStorePages } from '@stores'
import Svgs from '@svgs'
import Modal from '@components/global/modal/Modal.vue'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'

const storePages = useStorePages()
const storeModal = useStoreModal()
const emitter = inject('emitter')

const state = reactive({
  listaSites: []
})

function moverParaCima(index) {
  if (index > 0) {
    const item = state.listaSites[index]
    state.listaSites.splice(index, 1)
    state.listaSites.splice(index - 1, 0, item)
  }
}

function moverParaBaixo(index) {
  if (index < state.listaSites.length - 1) {
    const item = state.listaSites[index]
    state.listaSites.splice(index, 1)
    state.listaSites.splice(index + 1, 0, item)
  }
}

function formatarTitulo(titulo) {
  if (titulo.length <= 35) {
    return titulo
  }
  return titulo.substring(0, 35) + '...'
}

async function salvarPosicoes() {
  const payload = {
    ids: state.listaSites.map((page) => page._id)
  }

  const posicoesOk = await storePages.ordenarPosicoes(payload)
  if (posicoesOk !== true) return

  emitter.emit('atualizarPages')
  storeModal.fecharModal('posicoes')
  limparCampos()
}

function carregarListaSites() {
  if (storePages.pages) {
    state.listaSites = JSON.parse(JSON.stringify(storePages.pages))
  }
}

function limparCampos() {
  state.listaSites = []
}

onMounted(() => {
  emitter.on('carregarCampos', carregarListaSites)
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.conteudo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.itens {
  display: flex;
  flex-direction: column;
  height: 400px;
  min-height: 400px;
  max-height: 400px;
  width: 100%;
  overflow-y: scroll;
}

.itens::-webkit-scrollbar {
  display: none;
}

.item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background-color: var(--cor-cinza-2);
  border: 2px solid var(--cor-cinza-3);
  border-radius: 10px;
  margin: 0 0 10px 0;
  padding: 10px;
  position: relative;
}

.item .posicao {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  min-width: 20px;
  border-radius: 0 0 5px 0;
  background-color: var(--cor-cinza-3);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.item .posicao p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.item .capa {
  width: 80px;
  min-width: 80px;
}

.item .capa .tamanho {
  position: relative;
  padding-bottom: 40%;
  width: 100%;
}

.item .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
}

.item .modulo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 15px 0 0;
  margin: 0 0 0 10px;
}

.item .modulo h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
}

.item .modulo p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.item button.mover {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 35px;
  height: 35px;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
  transition: all 0.3s;
}

.item button.mover:disabled {
  opacity: 0.4;
  pointer-events: none;
}

.item button.mover.cima {
  right: 60px;
}

.item button.mover:hover {
  background-color: var(--cor-azul);
}

.item button.mover:hover svg {
  fill: var(--cor-branco-fixo);
}

.item button.mover.baixo {
  right: 15px;
}

.item button.mover svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
}

.item button.mover.cima svg {
  transform: rotate(-90deg);
}

.item button.mover.baixo svg {
  transform: rotate(90deg);
}

button.salvar {
  margin: 10px 0 0 0;
}

@media screen and (max-width: 1000px) {
  .item {
    padding: 10px 90px 10px 15px;
  }

  .item .capa {
    width: 70px;
    min-width: 70px;
  }

  .item .modulo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 0 10px;
  }

  .item .modulo p {
    font-size: var(--f1);
  }

  .item button.mover.baixo {
    right: 10px;
  }

  .item button.mover.cima {
    right: 50px;
  }
}
</style>
