<template>
  <div class="span">
    <h3>Cor do Destaque</h3>
    <h4>Use * (asterisco) no início e fim da palavra para *criar um destaque*</h4>

    <!-- Primeiro ColorPicker -->
    <div class="picker">
      <ColorPicker :valor="elementoSelecionado.spanColor" :onAtualizarCor="atualizarCorUm" />
    </div>

    <!-- Segundo ColorPicker -->
    <div class="picker">
      <ColorPicker :valor="elementoSelecionado.spanBackground" :onAtualizarCor="atualizarCorDois" />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStoreAjustes } from '@stores'
import ColorPicker from '@/components/global/elementos/ColorPicker.vue'

const props = defineProps(['selecao'])
const storeAjustes = useStoreAjustes()

const elementoSelecionado = computed(() => {
  const dispositivo = props.selecao?.dispositivo
  const indice = props.selecao?.indiceSecao
  const elemento = props.selecao?.elementoSelecionado
  return storeAjustes.secoes[dispositivo][indice]?.propriedades[elemento] || {}
})

function atualizarCorUm(novaCor) {
  elementoSelecionado.value.spanColor = novaCor
}

function atualizarCorDois(novaCor) {
  elementoSelecionado.value.spanBackground = novaCor
}
</script>

<style scoped>
.span {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
  animation: fadeIn 0.3s ease-in-out;
}

h3 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

h4 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.picker {
  margin: 10px 0 0 0;
}
</style>
