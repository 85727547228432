<template>
  <div id="criar">
    <Loading />

    <ModalSecoesDesktop />
    <ModalSecoesMobile />

    <ModalInformacoes />
    <ModalCompartilhar />

    <ModalExcluirSecaoDesktop />
    <ModalExcluirSecaoMobile />
    <ModalExcluirSite />

    <ModalDominio />
    <ModalReplicarParaMobile />
    <ModalFavicon />

    <ModalIntegrarFacebook />
    <ModalIntegrarGoogleTag />
    <ModalIntegrarCloakup />
    <ModalIntegracoes />

    <ModalSubir />
    <ModalCssPersonalizado />

    <SectionEdicao />
    <SectionAjustes />
    <SectionTela />

    <AutoLoad />
    <AutoSave />
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useStorePerfil } from '@stores'

import SectionAjustes from './partials/SectionAjustes.vue'
import SectionEdicao from './partials/SectionEdicao.vue'
import SectionTela from './partials/SectionTela.vue'

import AutoLoad from './partials/AutoLoad.vue'
import AutoSave from './partials/AutoSave.vue'

import Loading from './partials/Loading.vue'

import ModalDominio from './modals/ModalDominio.vue'
import ModalCssPersonalizado from './modals/ModalCssPersonalizado.vue'
import ModalReplicarParaMobile from './modals/ModalReplicarParaMobile.vue'

import ModalSecoesMobile from './modals/ModalSecoesMobile.vue'
import ModalSecoesDesktop from './modals/ModalSecoesDesktop.vue'

import ModalSubir from './modals/ModalSubir.vue'

import ModalIntegracoes from './modals/ModalIntegracoes.vue'
import ModalIntegrarGoogleTag from './modals/ModalIntegrarGoogleTag.vue'
import ModalIntegrarFacebook from './modals/ModalIntegrarFacebook.vue'
import ModalIntegrarCloakup from './modals/ModalIntegrarCloakup.vue'

import ModalCompartilhar from './modals/ModalCompartilhar.vue'
import ModalInformacoes from './modals/ModalInformacoes.vue'
import ModalFavicon from './modals/ModalFavicon.vue'

import ModalExcluirSecaoMobile from './modals/ModalExcluirSecaoMobile.vue'
import ModalExcluirSecaoDesktop from './modals/ModalExcluirSecaoDesktop.vue'
import ModalExcluirSite from '@components/app/sites/modals/ModalExcluirSite.vue'

const storePerfil = useStorePerfil()

function carregarPerfil() {
  storePerfil.receberPerfilUsuario()
}

onMounted(() => {
  carregarPerfil()
})
</script>

<style>
body {
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none !important;
}

body * {
  scrollbar-width: none;
}

body *::-webkit-scrollbar {
  display: none !important;
}
</style>
