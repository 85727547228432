<template>
  <div id="templates">
    <ModalCriarComTemplate />
    <ModalCriarDoZero />
    <ModalOpcoes />
    <SectionCriar />
    <SectionLista />
    <Footer />
  </div>
</template>

<script setup>
import ModalCriarComTemplate from '@components/app/templates/modals/ModalCriarComTemplate.vue'
import ModalCriarDoZero from '@components/app/sites/modals/ModalCriarDoZero.vue'
import ModalOpcoes from '@components/app/sites/modals/ModalOpcoes.vue'
import SectionCriar from '@components/app/templates/partials/SectionCriar.vue'
import SectionLista from '@components/app/templates/partials/SectionLista.vue'
import Footer from '@components/global/footer/Footer.vue'

import { onMounted, inject } from 'vue'
import { useStorePerfil, useStorePages } from '@stores'

const storePerfil = useStorePerfil()
const storePages = useStorePages()
const emitter = inject('emitter')

function carregarPerfil() {
  storePerfil.receberPerfilUsuario()
}

function carregarPages() {
  storePages.receberTodos()
}

onMounted(() => {
  carregarPerfil()
  carregarPages()
  emitter.on('atualizarPages', carregarPages)
})
</script>

<style scoped>
#templates {
  padding: 60px 0 0 0;
  animation: fadeIn 0.3s linear;
}
</style>
