<template>
  <div class="direction">
    <h3>Direção Flexível</h3>
    <div class="botoes">
      <button @click="selecionarDirection('row')" :class="{ ativo: state.directionSelecionado === 'row' }">
        <Svgs nome="direction-row" />
        <p>Linha</p>
      </button>
      <button @click="selecionarDirection('row-reverse')" :class="{ ativo: state.directionSelecionado === 'row-reverse' }">
        <Svgs nome="direction-row-reverse" />
        <p>Linha reversa</p>
      </button>
      <button @click="selecionarDirection('column')" :class="{ ativo: state.directionSelecionado === 'column' }">
        <Svgs nome="direction-column" />
        <p>Coluna</p>
      </button>
      <button @click="selecionarDirection('column-reverse')" :class="{ ativo: state.directionSelecionado === 'column-reverse' }">
        <Svgs nome="direction-column-reverse" />
        <p>Coluna reversa</p>
      </button>
    </div>
  </div>
</template>

<script setup>
import { reactive, watchEffect } from 'vue'
import { useStoreAjustes } from '@stores'
import Svgs from '@svgs'
const props = defineProps(['selecao'])

const storeAjustes = useStoreAjustes()

const state = reactive({
  directionSelecionado: ''
})

function selecionarDirection(direction) {
  state.directionSelecionado = direction
  elementoSelecionado().direction = direction
}

function aplicardirectionSelecionado() {
  const direction = elementoSelecionado().direction
  selecionarDirection(direction)
}

function elementoSelecionado() {
  const dispositivo = props?.selecao?.dispositivo
  const indice = props?.selecao?.indiceSecao
  const elemento = props?.selecao?.elementoSelecionado
  return storeAjustes.secoes[dispositivo][indice]?.propriedades[elemento] || {}
}

watchEffect(() => {
  aplicardirectionSelecionado()
})
</script>

<style scoped>
.direction {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
  animation: fadeIn 0.3s ease-in-out;
  border-top: 1px solid var(--cor-cinza-3);
}
h3 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 10px 0;
}
.botoes {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.botoes button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 10px 15px 20px;
  width: 100%;
  border-radius: 10px;
  margin: 0 0 10px 0;
  background-color: var(--cor-cinza-3);
  transition: all 0.3s;
}
.botoes button.ativo {
  background-color: var(--cor-cinza-4);
}
.botoes button p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  transition: all 0.3s;
}
.botoes button.ativo p {
  color: var(--cor-branco);
}
.botoes button:hover p {
  color: var(--cor-branco);
}
.botoes button svg {
  width: 15px;
  min-width: 15px;
  max-height: 15px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
  transition: all 0.3s;
}
.botoes button.direita svg {
  transform: rotate(90deg);
}
.botoes button.esquerda svg {
  transform: rotate(-90deg);
}
.botoes button.baixo svg {
  transform: rotate(180deg);
}
.botoes button.direita svg {
  transform: rotate(90deg);
}
</style>
