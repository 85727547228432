<template>
  <Modal nome="criarComTemplate">
    <div class="conteudo">
      <div class="template">
        <div class="capa">
          <div class="tamanho">
            <div class="imagem" :style="`background-image: url('${storeTemplates.imagemCapa}');`"></div>
          </div>
        </div>
        <div class="texto">
          <h3>Template Personalizado</h3>
          <div class="itens">
            <div class="autor">
              <div class="foto" :style="`background-image: url('${storeTemplates.iconeAutor}');`"></div>
              <p>{{ storeTemplates.autor }}</p>
            </div>
            <div class="tipo">
              <Svgs :class="iconeTemplate(storeTemplates.tipo)" :nome="iconeTemplate(storeTemplates.tipo)" />
              <p>{{ storeTemplates.tipo }}</p>
            </div>
          </div>
        </div>
      </div>

      <label class="um">
        Nome do site
        <span>*</span>
      </label>
      <input v-model="state.nomeDoSite" type="text" spellcheck="false" placeholder="Ex: Página de Vendas" @keydown.enter="criarSite" />

      <label>
        Descrição do site
        <span>*</span>
      </label>
      <textarea v-model="state.descricaoDoSite" spellcheck="false" placeholder="Ex: Foco em vendas de produtos" @keydown.enter="criarSite"></textarea>

      <label>
        URL do seu site
        <span>*</span>
      </label>
      <div class="inputUrl">
        <p>unic.page/</p>
        <input v-model="state.hotlinkSite" @input="validarHotlink()" spellcheck="false" type="text" placeholder="Digite o hotlink" @keydown.enter="criarSite" />
      </div>

      <label>Imagem de capa (opcional)</label>
      <div class="inputImagem" :class="{ carregar: state.loading }" @click="abrirInputFile">
        <span></span>
        <div class="previa" :style="`background-image: url('${state.fotoCapa || 'https://arquivos.unicpages.com/imagens/app/capa-site-unic.png'}')`">
          <Svgs v-if="!state.fotoCapa" nome="arquivo" />
        </div>
        <div class="texto">
          <h3>{{ state.nomeArquivo || 'Subir Capa' }}</h3>
          <p>{{ state.tamanhoArquivo || 'Tamanho recomendado: 800x160' }}</p>
        </div>
        <input type="file" accept=".jpg,.jpeg,.png" hidden @change="selecionarImagem" ref="inputFile" />
      </div>

      <BotaoSalvar nome="Criar com template" @click="criarSite" />
    </div>
  </Modal>
</template>
<script setup>
import { inject, onMounted, reactive, ref } from 'vue'
import { useStorePages, useStoreTemplates, useStoreModal } from '@stores'
import Svgs from '@svgs'
import Modal from '@components/global/modal/Modal.vue'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'
import ApiUpload from '@/api/upload/api-upload.js'

const emitter = inject('emitter')
const storeTemplates = useStoreTemplates()
const storePages = useStorePages()
const storeModal = useStoreModal()
const inputFile = ref(null)

const state = reactive({
  nomeDoSite: '',
  descricaoDoSite: '',
  hotlinkSite: '',
  fotoCapa: '',
  nomeArquivo: '',
  tamanhoArquivo: '',
  loading: false
})

function iconeTemplate(tipo) {
  switch (tipo) {
    case 'Página de Vendas':
      return 'pagina-vendas'
    case 'Página de Captura':
      return 'pagina-captura'
    case 'Link de Bio':
      return 'link-bio'
    default:
      return 'todos'
  }
}

function abrirInputFile() {
  inputFile.value.click()
}

function selecionarImagem(arquivo) {
  const arquivoSelecionado = arquivo.target.files[0]
  state.loading = true

  const payload = {
    arquivo: arquivoSelecionado,
    maxWidth: 800
  }

  ApiUpload.uploadImagem(payload)
    .then((resp) => {
      state.loading = false
      state.fotoCapa = resp?.url
      state.nomeArquivo = resp?.nome
      state.tamanhoArquivo = resp?.peso
    })
    .catch(() => {
      state.loading = false
      emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Erro ao subir imagem de capa' })
    })
}

function validarHotlink() {
  let hotlink = state.hotlinkSite

  hotlink = hotlink.toLowerCase()
  hotlink = hotlink.replace(/\s+/g, '-')
  hotlink = hotlink.replace(/[^a-z0-9-]/g, '')

  state.hotlinkSite = hotlink
}

async function criarSite() {
  if (!validarDados()) return

  const payload = {
    nome: state.nomeDoSite,
    descricao: state.descricaoDoSite,
    cor: 'FFFFFF',
    imagemCapa: state.fotoCapa,
    hotlink: state.hotlinkSite?.trim(),
    hotlinkTemplate: storeTemplates.hotlinkTemplate,
    metatags: {
      titulo: 'Seu site',
      descricao: 'Site criado com UnicPages',
      favicon: 'https://arquivos.unicpages.com/favicons/favicon.icohttps://arquivos.unicpages.com/favicons/favicon.png'
    }
  }

  const adicionadoOk = await storePages.clonarTemplate(payload)
  if (adicionadoOk !== true) return

  storeModal.fecharModal('criarComTemplate')
  setTimeout(() => {
    window.location.assign(`/criar/${state.hotlinkSite}`)
  }, 500)
}

function validarDados() {
  let mensagem
  if (!state.nomeDoSite || !state.descricaoDoSite || !state.hotlinkSite) mensagem = 'Por favor preencha todos os campos obrigatórios'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: mensagem })
  return false
}

function limparCampos() {
  state.nomeDoSite = ''
  state.descricaoDoSite = ''
  state.hotlinkSite = ''
  state.fotoCapa = ''
  state.nomeArquivo = ''
  state.tamanhoArquivo = ''
  state.loading = false
}

onMounted(() => {
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.template {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0 0 20px 0;
}

.template .capa {
  width: 100%;
  max-width: 180px;
}

.template .capa .tamanho {
  position: relative;
  padding-bottom: 56.52%;
  width: 100%;
}

.template .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--cor-preto);
  border-radius: 10px;
  background-size: cover;
  background-position: center;
}

.template .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 20px;
}

.template .texto h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.template .texto .itens {
  display: flex;
  align-items: center;
}

.template .texto .autor {
  display: flex;
  align-items: center;
  margin: 10px 10px 0 0;
}

.template .texto .autor .foto {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: var(--cor-cinza-3);
  background-size: cover;
  background-position: center;
  margin: 0 5px 0 0;
}

.template .texto .autor p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
}

.template .texto .tipo {
  display: flex;
  align-items: center;
  margin: 10px 0 0 0;
}

.template .texto .tipo p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
}

.template .texto .tipo svg {
  width: 10px;
  min-width: 10px;
  margin: 0 10px 0 3px;
}

.template .texto .tipo svg.pagina-vendas {
  fill: var(--cor-ciano);
}

.template .texto .tipo svg.pagina-captura {
  fill: var(--cor-amarelo);
}

.template .texto .tipo svg.link-bio {
  fill: var(--cor-rosa);
}

.conteudo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

label.um {
  margin: 0 0 10px 0;
}

button.salvar {
  margin: 20px 0 0 0;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .template {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 20px 0;
  }

  .template .capa {
    max-width: 100%;
  }

  .template .texto {
    padding: 20px 0 0 0;
  }
}
</style>
