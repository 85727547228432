<template>
  <div class="id">
    <h3>ID do elemento</h3>
    <h4>Adicione um ID no elemento para personalizar o CSS desse elemento</h4>
    <p>#</p>
    <input spellcheck="false" placeholder="Digite o ID aqui..." v-model="elementoId" />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStoreAjustes } from '@stores'

const props = defineProps(['selecao'])
const storeAjustes = useStoreAjustes()

const elementoSelecionado = computed(() => {
  const dispositivo = props.selecao?.dispositivo
  const indice = props.selecao?.indiceSecao
  const elemento = props.selecao?.elementoSelecionado
  return storeAjustes.secoes[dispositivo][indice]?.propriedades[elemento] || {}
})

const elementoId = computed({
  get() {
    return elementoSelecionado.value.id || ''
  },
  set(value) {
    elementoSelecionado.value.id = value
  }
})
</script>

<style scoped>
.id {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
  animation: fadeIn 0.3s ease-in-out;
  position: relative;
}

h3 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  width: 100%;
  margin: 0 0 5px 0;
}

h4 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  width: 100%;
  margin: 0 0 10px 0;
}

p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  position: absolute;
  left: 30px;
  bottom: 17px;
}

input {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  background-color: var(--cor-cinza-3);
  padding: 15px 15px 15px 25px;
  border-radius: 10px;
  line-height: 1.5;
  width: 100%;
  transition: all 0.3s;
}

input:focus {
  background-color: var(--cor-cinza-4);
}

input::placeholder {
  color: var(--cor-cinza-5);
}
</style>
