<template>
  <Modal nome="integracoes">
    <!-- plano free -->
    <div class="free" v-if="storePerfil.usuario?.assinatura?.tipo === 'free'">
      <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-free.svg" />
      <h3>Você está no plano free</h3>
      <p>No plano free você não tem permissão para integrar! Integrações com facebook pixel e google tag manager só estão disponíveis para assinantes Silver, Gold ou Diamond</p>
      <button @click="router.push('/planos')">
        <Svgs nome="estrela" />
        Ver Planos
      </button>
    </div>
    <div class="opcoes" v-else>
      <!-- Opções -->
      <button class="botao um" @click="abrirModalGoogleTag()" :class="{ ativo: state.googleTag }">
        <div class="icone">
          <Svgs nome="google" />
        </div>
        <div class="texto">
          <div class="status">
            <span></span>
            <h4>{{ state.googleTag ? 'Configurado' : 'Não configurado' }}</h4>
          </div>
          <h3>Google Tag Manager</h3>
          <p>Instale o script do seu Google Tag</p>
        </div>
        <Svgs class="setinha" nome="setinha" />
      </button>

      <button class="botao" @click="abrirModalFacebook()" :class="{ ativo: state.pixelFacebook }">
        <div class="icone">
          <Svgs nome="meta" />
        </div>
        <div class="texto">
          <div class="status">
            <span></span>
            <h4>{{ state.pixelFacebook ? 'Configurado' : 'Não configurado' }}</h4>
          </div>
          <h3>Pixel Facebook Meta</h3>
          <p>Instale o pixel do seu facebook</p>
        </div>
        <Svgs class="setinha" nome="setinha" />
      </button>

      <button class="botao" @click="abrirModalCloakup()" :class="{ ativo: state.cloakup }">
        <div class="icone">
          <Svgs nome="cloakup" />
        </div>
        <div class="texto">
          <div class="status">
            <span></span>
            <h4>{{ state.cloakup ? 'Configurado' : 'Não configurado' }}</h4>
          </div>
          <h3>Cloakup</h3>
          <p>Adicione as URLs da sua conta Cloakup</p>
        </div>
        <Svgs class="setinha" nome="setinha" />
      </button>
    </div>
  </Modal>
</template>

<script setup>
import { onMounted, inject, reactive } from 'vue'
import { useStorePages, useStoreModal, useStorePerfil } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'
import Modal from '@components/global/modal/Modal.vue'

const storePages = useStorePages()
const storeModal = useStoreModal()
const storePerfil = useStorePerfil()
const emitter = inject('emitter')
const router = useRouter()

const state = reactive({
  googleTag: false,
  pixelFacebook: false,
  cloakup: false
})

function abrirModalGoogleTag() {
  storeModal.fecharModal('integracoes')
  storeModal.abrirModal('googleTag', 'google', 'Configurar Google Tag')
}

function abrirModalFacebook() {
  storeModal.fecharModal('integracoes')
  storeModal.abrirModal('facebookMeta', 'meta', 'Configurar Pixel Facebook')
}

function abrirModalCloakup() {
  storeModal.fecharModal('integracoes')
  storeModal.abrirModal('cloakup', 'cloakup', 'Configurar URLs Cloakup')
}

function verificarIntegracoes() {
  if (!storePages.page?.integracoes?.tagManager?.id) {
    state.googleTag = false
  } else {
    state.googleTag = true
  }
  if (!storePages.page?.integracoes?.facebook?.id) {
    state.pixelFacebook = false
  } else {
    state.pixelFacebook = true
  }
  if (!storePages.page?.integracoes?.cloakUp?.urls?.length) {
    state.cloakup = false
  } else {
    state.cloakup = true
  }
}

onMounted(() => {
  emitter.on('carregarCampos', verificarIntegracoes)
})
</script>

<style scoped>
.free {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 0;
}
.free img {
  width: 100%;
  max-width: 50px;
  margin: 0 0 20px 0;
}

.free h3 {
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco);
  text-align: center;
}

.free p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  text-align: center;
  line-height: 1.5;
  width: 100%;
  max-width: 400px;
  margin: 5px 0 10px 0;
}

.free button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  text-align: center;
  background-color: var(--cor-azul);
  padding: 15px 30px;
  border-radius: 10px;
  transition: all 0.3s;
}

.free button:hover {
  background-color: var(--cor-azul-escuro);
}

.free button svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

.opcoes {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.botao {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
  width: 100%;
  padding: 20px;
  position: relative;
  margin: 0 0 20px 0;
  transition: all 0.3s;
}

.botao:hover {
  background-color: var(--cor-cinza-4);
}

.botao .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  min-width: 70px;
  height: 70px;
  border-radius: 20px;
  border: 2px solid var(--cor-vermelho);
  margin: 0 20px 0 0;
}

.botao.ativo .icone {
  border: 2px solid var(--cor-azul);
}

.botao .icone svg {
  width: 25px;
  min-width: 25px;
  max-height: 25px;
  fill: var(--cor-branco);
}

.botao .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.botao .texto .status {
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
}

.botao .texto .status h4 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
}

.botao.ativo .texto .status span {
  background-color: var(--cor-azul);
}

.botao .texto .status span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--cor-vermelho);
  margin: 0 5px 0 0;
}

.botao .texto h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.botao .texto p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.botao .texto p span {
  font-family: var(--bold);
  color: var(--cor-azul);
}

.botao svg.setinha {
  position: absolute;
  right: 20px;
  width: 15px;
  min-width: 15px;
  fill: var(--cor-cinza-5);
  transform: rotate(-90deg);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .titulo {
    padding: 20px;
  }

  .botao {
    padding: 20px;
  }

  .pixel {
    padding: 20px 20px 0 20px;
  }

  .pixel .linha {
    padding: 0 0 0 10px;
  }
}
</style>
