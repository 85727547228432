<template>
  <div class="color" @keydown.esc="fecharColorPicker">
    <div class="item" :class="{ ativo: state.colorPicker.visible }" ref="balaoCores">
      <div class="info">
        <div class="cor" :style="{ backgroundColor: state.corLimpa }" @click="abrirColorPicker"></div>
        <input type="text" v-model="state.corLimpa" @input="onInputCor" />
      </div>
      <div class="balao">
        <div class="titulo">
          <p>Selecione uma cor</p>
          <button class="fechar" @click="fecharColorPicker">
            <Svgs nome="x" />
          </button>
        </div>
        <div class="color-picker"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, onMounted, ref, watch, nextTick } from 'vue'
import Pickr from '@simonwep/pickr'
import Svgs from '@svgs'
import { helperClicouFora } from '@helpers'

const props = defineProps(['valor', 'onAtualizarCor'])
const balaoCores = ref(null)

const state = reactive({
  colorPicker: {
    visible: false,
    color: ''
  },
  corLimpa: props.valor || '#000000'
})

let pickr = null

function criarColorPicker() {
  pickr = Pickr.create({
    el: balaoCores.value.querySelector('.color-picker'),
    theme: 'nano',
    default: state.corLimpa,
    swatches: ['#FFFFFF', '#000000', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107', '#FF9800', '#FF5722'],
    components: {
      preview: true,
      opacity: true,
      hue: true,
      interaction: {
        hex: false,
        rgba: false,
        hsla: false,
        input: true,
        clear: false,
        save: false
      }
    },
    container: balaoCores.value.querySelector('.balao')
  })

  pickr.on('change', (color) => {
    const hexaColor = color.toHEXA().toString()
    atualizarCor(hexaColor)
  })
}

function atualizarCor(valor) {
  state.corLimpa = valor
  if (typeof props.onAtualizarCor === 'function') {
    props.onAtualizarCor(valor.replace(/^#/, ''))
  }
}

function abrirColorPicker() {
  state.colorPicker.visible = true
  pickr.show()
}

function fecharColorPicker() {
  state.colorPicker.visible = false
  pickr.addSwatch(state.corLimpa)
  pickr.hide()
}

function onInputCor(event) {
  const valor = event.target.value
  state.corLimpa = valor

  if (/^#([0-9A-Fa-f]{6})$/.test(valor)) {
    if (pickr) pickr.setColor(valor)
    if (typeof props.onAtualizarCor === 'function') {
      props.onAtualizarCor(valor.replace(/^#/, ''))
    }
  }
}

helperClicouFora.clicouFora([{ alvo: balaoCores, retornoChamada: fecharColorPicker }])

watch(
  () => props.valor,
  (novaCor) => {
    const corComHash = novaCor.startsWith('#') ? novaCor : `#${novaCor}`
    state.corLimpa = corComHash
    if (pickr) pickr.setColor(corComHash)
  },
  { immediate: true }
)

onMounted(async () => {
  await nextTick()
  criarColorPicker()
})
</script>

<style scoped>
.color {
  position: relative;
}

.item {
  display: flex;
  align-items: center;
  width: 100%;
}

.info {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.info input {
  background-color: var(--cor-cinza-3);
  padding: 11px 11px 11px 50px;
}

.info input:focus {
  background-color: var(--cor-cinza-4);
}

.info .cor {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 10px;
  position: absolute;
  left: 0;
  cursor: pointer;
  transition: transform 0.3s;
}

.info .cor:hover {
  transform: rotate(5deg);
}

.item .balao {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: fixed;
  background-color: var(--cor-cinza-2);
  bottom: 50px;
  border-radius: 10px;
  padding: 0;
  right: 270px;
  width: 100%;
  max-width: 250px;
  height: 460px;
  max-height: 460px;
  min-height: 460px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 4;
  transition: all 0.5s;
}

.item.ativo .balao {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.item .balao .titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

.item .balao .titulo p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.item .balao button.fechar {
  background-color: transparent;
}

.item .balao button.fechar svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

.item .balao button.fechar:hover svg {
  fill: var(--cor-vermelho);
}

.color-picker {
  width: 100%;
  height: 200px;
}
</style>
