<template>
  <div id="sites">
    <SectionCriar />
    <SectionLista />
    <Footer />
  </div>
</template>

<script setup>
import { onMounted, inject } from 'vue'
import { useStorePerfil, useStorePages } from '@stores'
import SectionCriar from './partials/SectionCriar.vue'
import SectionLista from './partials/SectionLista.vue'
import Footer from '../../global/footer/Footer.vue'

const storePerfil = useStorePerfil()
const storePages = useStorePages()
const emitter = inject('emitter')

function carregarPerfil() {
  storePerfil.receberPerfilUsuario()
}

function carregarPagesCompartilhados() {
  storePages.receberCompartilhados()
}

onMounted(() => {
  carregarPerfil()
  carregarPagesCompartilhados()
  emitter.on('atualizarPagesCompartilhados', carregarPagesCompartilhados)
})
</script>

<style scoped>
#sites {
  padding: 60px 0 0 0;
  animation: fadeIn 0.3s linear;
}
</style>
