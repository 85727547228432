<template>
  <div class="justifyContent">
    <h3>Justificar Conteúdo</h3>
    <div class="botoes">
      <button @click="selecionarJustify('flex-start')" :class="{ ativo: state.justifySelecionado === 'flex-start' }">
        <Svgs nome="alinhar-flex-start" />
        <p>Justificar ao início</p>
      </button>
      <button @click="selecionarJustify('flex-end')" :class="{ ativo: state.justifySelecionado === 'flex-end' }">
        <Svgs nome="alinhar-flex-end" />
        <p>Justificar ao final</p>
      </button>
      <button @click="selecionarJustify('center')" :class="{ ativo: state.justifySelecionado === 'center' }">
        <Svgs nome="alinhar-center" />
        <p>Justificar ao centro</p>
      </button>
      <button @click="selecionarJustify('space-between')" :class="{ ativo: state.justifySelecionado === 'space-between' }">
        <Svgs nome="alinhar-space-between" />
        <p>Justificar por igual</p>
      </button>
    </div>
  </div>
</template>

<script setup>
import { reactive, watchEffect } from 'vue'
import { useStoreAjustes } from '@stores'
import Svgs from '@svgs'
const props = defineProps(['selecao'])

const storeAjustes = useStoreAjustes()

const state = reactive({
  justifySelecionado: ''
})

function selecionarJustify(justify) {
  state.justifySelecionado = justify
  elementoSelecionado().justifyContent = justify
}

function aplicarjustifySelecionado() {
  const justify = elementoSelecionado().justifyContent
  selecionarJustify(justify)
}

function elementoSelecionado() {
  const dispositivo = props?.selecao?.dispositivo
  const indice = props?.selecao?.indiceSecao
  const elemento = props?.selecao?.elementoSelecionado
  return storeAjustes.secoes[dispositivo][indice]?.propriedades[elemento] || {}
}

watchEffect(() => {
  aplicarjustifySelecionado()
})
</script>

<style scoped>
.justifyContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
  animation: fadeIn 0.3s ease-in-out;
}
h3 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 10px 0;
}
.botoes {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.botoes button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 10px 15px 20px;
  width: 100%;
  border-radius: 10px;
  margin: 0 0 10px 0;
  background-color: var(--cor-cinza-3);
  transition: all 0.3s;
}
.botoes button.ativo {
  background-color: var(--cor-cinza-4);
}
.botoes button p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  transition: all 0.3s;
}
.botoes button.ativo p {
  color: var(--cor-branco);
}
.botoes button:hover p {
  color: var(--cor-branco);
}
.botoes button svg {
  width: 15px;
  min-width: 15px;
  max-height: 11px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
  transition: all 0.3s;
}
.botoes button.direita svg {
  transform: rotate(90deg);
}
.botoes button.esquerda svg {
  transform: rotate(-90deg);
}
.botoes button.baixo svg {
  transform: rotate(180deg);
}
.botoes button.direita svg {
  transform: rotate(90deg);
}
</style>
