<template>
  <div class="background" v-if="!elementoSelecionado.gradientActive">
    <h3>Fundo</h3>
    <ColorPicker :valor="elementoSelecionado.backgroundColor" :onAtualizarCor="atualizarCorSelecionada" />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStoreAjustes } from '@stores'
import ColorPicker from '@/components/global/elementos/ColorPicker.vue'

const props = defineProps(['selecao'])
const storeAjustes = useStoreAjustes()

const elementoSelecionado = computed(() => {
  const dispositivo = props.selecao?.dispositivo
  const indice = props.selecao?.indiceSecao
  const elemento = props.selecao?.elementoSelecionado
  return storeAjustes.secoes[dispositivo][indice]?.propriedades[elemento] || {}
})

function atualizarCorSelecionada(novaCor) {
  elementoSelecionado.value.backgroundColor = novaCor
}
</script>

<style scoped>
.background {
  position: relative;
  padding: 0 20px 20px 20px;
  border-bottom: 1px solid var(--cor-cinza-3);
  width: 100%;
}

h3 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  padding: 20px 0 10px 0;
}
</style>
