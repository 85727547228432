<template>
  <Modal nome="opcoesCriacao">
    <div class="opcoes">
      <button @click="escolherCriar()">
        <div class="icone">
          <Svgs nome="cursor" />
        </div>
        <div class="texto">
          <h3>Criar do zero</h3>
          <p>Crie seu site do absoluto zero, seção por seção</p>
        </div>
      </button>
      <button @click="escolherTemplates()">
        <div class="icone">
          <Svgs nome="template" />
        </div>
        <div class="texto">
          <h3>Template Pronto</h3>
          <p>Crie seu site usando os templates prontos da Unic</p>
        </div>
      </button>
    </div>
  </Modal>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useStoreModal } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import Svgs from '@svgs'

const router = useRouter()
const storeModal = useStoreModal()

function escolherTemplates() {
  storeModal.fecharModal('opcoesCriacao')
  router.push('/templates')
}

function escolherCriar() {
  storeModal.abrirModal('criarDoZero', 'cursor', 'Criar site do zero')
  storeModal.fecharModal('opcoesCriacao')
}
</script>

<style scoped>
.opcoes {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
button.tres,
button.quatro {
  margin: 0;
}
button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-2);
  padding: 40px 20px;
  border-radius: 10px;
  border: 1px solid var(--cor-cinza-3);
  width: calc(50% - 10px);
  transition: all 0.3s;
  position: relative;
}

button:hover {
  border: 1px solid var(--cor-azul);
}

button.breve {
  opacity: 0.4;
  pointer-events: none;
  background-color: var(--cor-cinza-3);
}

button.breve span {
  position: absolute;
  top: 10px;
  right: 10px;
  font-family: var(--bold);
  font-size: var(--f0);
  color: var(--cor-branco);
  background-color: var(--cor-cinza-3);
  padding: 5px 10px;
  border-radius: 5px;
}
button .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 20px;
  background-color: var(--cor-cinza-3);
}
button .icone svg {
  width: 20px;
  min-width: 20px;
  max-height: 20px;
  fill: var(--cor-branco);
}
button h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 15px 0 5px 0;
}
button p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  width: 100%;
  max-width: 170px;
}
@media screen and (max-width: 1024px) {
  .opcoes {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }
  button.tres,
  button.quatro {
    margin: 0;
  }
  button.tres {
    margin: 0 0 20px 0;
  }
  button {
    flex-direction: row;
    justify-content: flex-start;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    margin: 0 0 20px 0;
    transition: all 0.3s;
  }
  button:hover {
    border: 1px solid var(--cor-azul);
  }
  button .texto {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    padding: 0 0 0 15px;
  }
  button h3 {
    font-size: var(--f2);
    margin: 0 0 5px 0;
  }
  button p {
    width: 100%;
    max-width: 170px;
  }
}
</style>
