<template>
  <div class="borderRadius">
    <h3>
      Arredondamento
      <button @click="zerarTudo">zerar</button>
    </h3>
    <div class="itens">
      <div class="item um">
        <Svgs nome="arredondamento" />
        <input type="tel" placeholder="0" spellCheck="false" v-model="borderRadiusTop" />
      </div>
      <div class="item dois">
        <Svgs nome="arredondamento" />
        <input type="tel" placeholder="0" spellCheck="false" v-model="borderRadiusRight" />
      </div>
      <div class="item tres">
        <Svgs nome="arredondamento" />
        <input type="tel" placeholder="0" spellCheck="false" v-model="borderRadiusLeft" />
      </div>
      <div class="item quatro">
        <Svgs nome="arredondamento" />
        <input type="tel" placeholder="0" spellCheck="false" v-model="borderRadiusBottom" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStoreAjustes } from '@stores'
import Svgs from '@svgs'

const props = defineProps(['selecao'])
const storeAjustes = useStoreAjustes()

const elementoSelecionado = computed(() => {
  const dispositivo = props.selecao?.dispositivo
  const indice = props.selecao?.indiceSecao
  const elemento = props.selecao?.elementoSelecionado
  return storeAjustes.secoes[dispositivo][indice]?.propriedades[elemento] || {}
})

const borderRadiusTop = computed({
  get() {
    return elementoSelecionado.value.borderRadius?.top || 0
  },
  set(value) {
    elementoSelecionado.value.borderRadius.top = value
  }
})

const borderRadiusRight = computed({
  get() {
    return elementoSelecionado.value.borderRadius?.right || 0
  },
  set(value) {
    elementoSelecionado.value.borderRadius.right = value
  }
})

const borderRadiusLeft = computed({
  get() {
    return elementoSelecionado.value.borderRadius?.left || 0
  },
  set(value) {
    elementoSelecionado.value.borderRadius.left = value
  }
})

const borderRadiusBottom = computed({
  get() {
    return elementoSelecionado.value.borderRadius?.bottom || 0
  },
  set(value) {
    elementoSelecionado.value.borderRadius.bottom = value
  }
})

function zerarTudo() {
  borderRadiusTop.value = 0
  borderRadiusRight.value = 0
  borderRadiusBottom.value = 0
  borderRadiusLeft.value = 0
}
</script>

<style scoped>
.borderRadius {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
  animation: fadeIn 0.3s ease-in-out;
  border-top: 1px solid var(--cor-cinza-3);
}

h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  width: 100%;
}

h3 button {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  background-color: var(--cor-cinza-3);
  border-radius: 50px;
  padding: 5px 10px;
  transition: all 0.3s;
}

h3 button:hover {
  color: var(--cor-branco);
  background-color: var(--cor-cinza-4);
}

.itens {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.itens .item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50% - 5px);
  padding: 10px 0 0 0;
  position: relative;
}

.itens .item input {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  padding: 15px 10px 15px 40px;
  width: 100%;
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
  transition: all 0.3s;
}

.itens .item input:focus {
  background-color: var(--cor-cinza-4);
}

.itens .item input::placeholder {
  color: var(--cor-cinza-5);
}

.itens .item svg {
  position: absolute;
  left: 10px;
  width: 20px;
  min-width: 20px;
  fill: var(--cor-branco);
  pointer-events: none;
}

.itens .item.dois svg {
  transform: rotate(90deg);
}

.itens .item.tres svg {
  transform: rotate(270deg);
}

.itens .item.quatro svg {
  transform: rotate(180deg);
}
</style>
