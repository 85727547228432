<template>
  <Modal nome="criarDoZero">
    <div class="conteudo">
      <label class="um">
        Nome do site
        <span>*</span>
      </label>
      <input v-model="state.nomeDoSite" type="text" spellcheck="false" placeholder="Ex: Página de Vendas" @keydown.enter="criarSite" />

      <label>
        Descrição do site
        <span>*</span>
      </label>
      <textarea v-model="state.descricaoDoSite" spellcheck="false" placeholder="Ex: Foco em vendas de produtos" @keydown.enter="criarSite"></textarea>

      <label>
        URL do seu site
        <span>*</span>
      </label>
      <div class="inputUrl">
        <p>unic.page/</p>
        <input v-model="state.hotlinkSite" @input="validarHotlink()" spellcheck="false" type="text" placeholder="Digite o hotlink" @keydown.enter="criarSite" />
      </div>

      <label>Imagem de capa (opcional)</label>
      <div class="inputImagem" :class="{ carregar: state.loading }" @click="abrirInputFile">
        <span></span>
        <div class="previa" :style="`background-image: url('${state.fotoCapa || 'https://arquivos.unicpages.com/imagens/app/capa-site-unic.png'}')`">
          <Svgs v-if="!state.fotoCapa" nome="arquivo" />
        </div>
        <div class="texto">
          <h3>{{ state.nomeArquivo || 'Subir Capa' }}</h3>
          <p>{{ state.tamanhoArquivo || 'Tamanho recomendado: 800x160' }}</p>
        </div>
        <input type="file" accept=".jpg,.jpeg,.png" hidden @change="selecionarImagem" ref="inputFile" />
      </div>

      <BotaoSalvar nome="Criar site agora" @click="criarSite" />
    </div>
  </Modal>
</template>
<script setup>
import { inject, ref, reactive, onMounted } from 'vue'
import { useStorePages, useStoreModal } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'
import ApiUpload from '@/api/upload/api-upload.js'
import Svgs from '@svgs'

const emitter = inject('emitter')
const storePages = useStorePages()
const storeModal = useStoreModal()
const inputFile = ref(null)

const state = reactive({
  nomeDoSite: '',
  descricaoDoSite: '',
  hotlinkSite: '',
  fotoCapa: '',
  nomeArquivo: '',
  tamanhoArquivo: '',
  loading: false
})

function abrirInputFile() {
  inputFile.value.click()
}

function selecionarImagem(arquivo) {
  const arquivoSelecionado = arquivo.target.files[0]
  state.loading = true

  const payload = {
    arquivo: arquivoSelecionado,
    maxWidth: 800
  }

  ApiUpload.uploadImagem(payload)
    .then((resp) => {
      state.loading = false
      state.fotoCapa = resp?.url
      state.nomeArquivo = resp?.nome
      state.tamanhoArquivo = resp?.peso
    })
    .catch(() => {
      state.loading = false
      emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Erro ao subir imagem de capa' })
    })
}

function validarHotlink() {
  let hotlink = state.hotlinkSite

  hotlink = hotlink.toLowerCase()
  hotlink = hotlink.replace(/\s+/g, '-')
  hotlink = hotlink.replace(/[^a-z0-9-]/g, '')

  state.hotlinkSite = hotlink
}

async function criarSite() {
  if (!validarDados()) return

  const payload = {
    nome: state.nomeDoSite,
    descricao: state.descricaoDoSite,
    cor: 'FFFFFF',
    imagemCapa: state.fotoCapa,
    hotlink: state.hotlinkSite?.trim(),
    metatags: {
      titulo: 'Seu site',
      descricao: 'Site criado com UnicPages',
      favicon: 'https://arquivos.unicpages.com/favicons/favicon.icohttps://arquivos.unicpages.com/favicons/favicon.png'
    },
    codigoFonte: {
      rascunho: {
        desktop: JSON.stringify([]),
        mobile: JSON.stringify([])
      }
    }
  }

  const adicionadoOk = await storePages.adicionar(payload)
  if (adicionadoOk !== true) return

  emitter.emit('atualizarPages')
  storeModal.fecharModal('criarDoZero')
  emitter.emit('mostrarAlerta', { tipo: 'positivo', mensagem: 'Site criado com sucesso!' })
  setTimeout(() => {
    window.location.assign(`/criar/${state.hotlinkSite}`)
  }, 1000)
}

function validarDados() {
  let mensagem
  if (!state.nomeDoSite || !state.descricaoDoSite || !state.hotlinkSite) mensagem = 'Por favor preencha todos os campos obrigatórios'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: mensagem })
  return false
}

function limparCampos() {
  state.nomeDoSite = ''
  state.descricaoDoSite = ''
  state.corDeDestaque = ''
  state.hotlinkSite = ''
  state.fotoCapa = ''
  state.nomeArquivo = ''
  state.tamanhoArquivo = ''
  state.loading = false
}

onMounted(() => {
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.conteudo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

label.um {
  margin: 0 0 10px 0;
}

button.salvar {
  margin: 20px 0 0 0;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
}
</style>
